







































































import KTextInput from '@/@core/components/input/KTextInput.vue';
import KHamburgerMenu from '@/@core/components/navigation/KHamburgerMenu.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KHeaderBackButton from '@/@core/components/page/KHeaderBackButton.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KText from '@/@core/components/typography/KText.vue';
import router from '@/router';
import { useIndustryStore } from '../../services/store';
import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref,
  unref
} from '@vue/composition-api';
import { IndustryForm } from '../../types';
import CommonForm from '../../ui/CommonForm.vue';
import { useInitIndustryForm } from '../../helpers/initIndustryForm';
import FormInfo from '../../ui/FormInfo.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import NotePanel from '@/modules/note/ui/NotePanel.vue';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KHeaderBackButton,
    KHamburgerMenu,
    KText,
    KPageBody,
    KWrapper,
    CommonForm,
    KTextInput,
    KCard,
    FormInfo,
    NotePanel
  },
  name: 'IndustryFormPage',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const store = useIndustryStore();
    const reRenderCount = ref(0);
    const isLoading = ref(false);
    const showPanel = ref(false);
    const formData: Ref<IndustryForm> = ref({
      district: '',
      village: '',
      name: '',
      address: '',
      geolocation: {
        latitude: undefined,
        longitude: undefined
      },
      npwp: '',
      businessEntity: '',
      ownerName: '',
      inChargeName: '',
      ownerAddress: '',
      phoneNumber: '',
      classification: undefined,
      employees: {
        male: undefined,
        female: undefined
      },
      commodities: [],
      permissions: [],
      products: [],
      tags: [],
      finance: undefined,
    });

    const useFieldToText = (field: string) => {
      const fields: any = {
        district: 'Kecamatan',
        name: 'Nama Industri',
        address: 'Alamat Industri',
        npwp: 'NPWP',
        geolocationLatitude: 'Titik Lokasi (Latitude)',
        geolocationLongitude: 'Titik Lokasi (Longitude)',
        businessEntity: 'Bentuk Badan Usaha',
        ownerName: 'Nama Pemilik',
        ownerAddress: 'Alamat Pemilik',
        inChargeName: 'Nama Penanggung Jawab',
        phoneNumber: 'Nomor Telepon / HP',
        classification: 'KBLI',
        employeesMale: 'Jumlah Pegawai (Laki-laki)',
        employeesFemale: 'Jumlah Pegawai (Perempuan)',
        commodities: 'Komoditi',
        tags: 'Tag Produk',
        finance: 'Pembiayaan',
      };

      return fields[field] || null;
    };

    const useMissingData = (requiredData: string[]) => {
      const missingData: any[] = [];

      requiredData.map((key) => {
        const isMissing = !(<any>formData).value[key];
        if (isMissing)
          missingData.push({
            field: key,
            text: useFieldToText(key)
          });
      });

      return missingData;
    };

    const commonFormRequiredData = [
      'district',
      'name',
      'address',
      'geolocation',
      'npwp',
      'businessEntity',
      'ownerName',
      'ownerAddress',
      'inChargeName',
      'phoneNumber',
      'classification'
    ];

    const missingCommonForm = computed(() => {
      const pushToMissingData = (key: string) =>
        missingData.push({
          field: key,
          text: useFieldToText(key)
        });

      const missingData = useMissingData(commonFormRequiredData);

      if (!formData.value?.geolocation?.latitude)
        pushToMissingData('geolocationLatitude');
      if (!formData.value?.geolocation?.longitude)
        pushToMissingData('geolocationLongitude');
      if (!formData.value?.classification?.id)
        pushToMissingData('classification');
      if (!formData.value?.commodities?.length)
        pushToMissingData('commodities');
      if (!formData.value?.employees.male)
        pushToMissingData('employeesMale');
      if (!formData.value?.employees.female)
        pushToMissingData('employeesFemale');
      if (!formData.value?.tags?.length) pushToMissingData('tags');
      if (!formData.value?.finance?.id)
        pushToMissingData('finance');

      return missingData;
    });

    const isCommonFormComplete = computed(() => {
      const filledData = [];

      commonFormRequiredData.map((key) => {
        const hasData = (<any>formData).value[key];
        if (hasData) filledData.push(true);
      });

      const isComplete =
        filledData.length == commonFormRequiredData.length;

      return isComplete;
    });

    const isPermissionFormComplete = computed(
      () => !!formData.value?.permissions?.length
    );

    const isProductFormComplete = computed(
      () => !!formData.value?.products?.length
    );

    const isInvesmentFormComplete = computed(
      () => !!formData.value?.totalInvestmentValue
    );

    const isMaterialFormComplete = computed(
      () => !!formData.value?.materials?.length
    );

    const isOperationalFormComplete = computed(
      () => !!formData.value?.operationalCosts?.length
    );

    const isOtherCostFormComplete = computed(
      () => !!formData.value?.otherCosts?.length
    );

    const isWageFormComplete = computed(
      () => !!formData.value?.wages?.length
    );

    const isCostFormComplete = computed(
      () => !!formData.value?.costs
    );

    const titleBar = computed(() => {
      const isEdit = props.id;
      const hasName = formData.value.name;

      if (isEdit && hasName) return formData.value.name;
      return 'Industri Baru';
    });

    const goToForm = (path: string) => {
      const isEdit = props.id;
      const destinationPath = isEdit ? props.id : 'tambah';

      router.push(`/app/industri/${destinationPath}/${path}`);
    };

    const sections: Ref<any[]> = ref([
      {
        title: 'Informasi Umum',
        complete: computed(() => isCommonFormComplete.value),
        missingData: computed(() => missingCommonForm.value),
        to: 'umum'
      },
      {
        disabled: !props.id,
        title: 'Perizinan',
        complete: computed(() => isPermissionFormComplete.value),
        to: 'izin'
      },
      {
        disabled: !props.id,
        title: 'Produk',
        complete: computed(() => isProductFormComplete.value),
        to: 'produk'
      },
      {
        disabled: !props.id,
        title: 'Nilai Investasi',
        complete: computed(() => isInvesmentFormComplete.value),
        to: 'nilai-investasi'
      },
      {
        disabled: !props.id,
        title: 'Material',
        complete: computed(() => isMaterialFormComplete.value),
        to: 'material'
      },
      {
        disabled: !props.id,
        title: 'Operasional',
        complete: computed(() => isOperationalFormComplete.value),
        to: 'operasional'
      },
      {
        disabled: !props.id,
        title: 'Gaji',
        complete: computed(() => isWageFormComplete.value),
        to: 'gaji'
      },
      {
        disabled: !props.id,
        title: 'Biaya Lainnya',
        complete: computed(() => isOtherCostFormComplete.value),
        to: 'biaya-lainnya'
      },
      {
        disabled: !props.id,
        title: 'Biaya',
        complete: computed(() => isCostFormComplete.value),
        to: 'biaya'
      }
    ]);

    const notesCount = computed(() => formData.value?.notes?.length);

    const getIndustry = async () => {
      const isEdit = props.id;

      if (isEdit) {
        const industry = await useInitIndustryForm(props.id);
        if (industry) {
          formData.value = {
            ...formData.value,
            ...industry
          };
        }

        reRenderCount.value++;
      }
    };

    onMounted(async () => {
      await getIndustry();
    });

    return {
      formData,
      isLoading,
      showPanel,
      isCommonFormComplete,
      titleBar,
      goToForm,
      reRenderCount,
      isPermissionFormComplete,
      isProductFormComplete,
      sections,
      isMobile,
      missingCommonForm,
      getIndustry,
      notesCount
    };
  }
});
